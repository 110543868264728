<template>
  <div class="placeOder_content">
    <!-- 地址 -->

    <div class="vancard-header" @click="goAddress()">
      <div class="vancard-first">
        <span v-if="addresslist.receiver_name" style="margin-right: 20px">
          {{ addresslist.receiver_name }}
        </span>
        <span v-if="addresslist.name" style="margin-right: 20px">
          {{ addresslist.name }}
        </span>
        <span>{{ addresslist.tel }}</span>
      </div>

      <div class="vancard-second">
        <div v-if="addresslist.isDefault === 1 || addresslist.is_default === 1" class="vancard-is_default">
          默认
        </div>
        <div class="vancard-address">
          {{ addresslist.province_name }}{{ addresslist.city_name }}{{ addresslist.address }}
        </div>
      </div>
      <div v-if="!(addresslist && (addresslist.addressId || addresslist.address_id))" class="address-empty">
        添加收货地址
      </div>
      <div v-else class="vancard-icon">
        <van-icon name="arrow" />
      </div>
    </div>

    <!-- 商品卡片 -->
    <div v-for="item in goodsList" :key="item.supplierId" class="group_xxon">
      <div class="group_xxon_title">
        <p class="img">
          <img :src="item.imgIcon" alt>
        </p>
        <p class="name">{{ item.name }}</p>
      </div>
      <div v-for="items in item.data" :key="items.goodId" class="group_box">
        <div class="group_top">
          <div class="group_image">
            <img :src="items.goodCover" alt>
          </div>
          <div class="group_detail">
            <div class="group_detail_title">
              {{ items.goodName }}
            </div>
            <div class="group_detail_txt">{{ items.goodSpec }}</div>
            <div class="group_detail_lay">
              ¥{{ items.vipPrice }}
              <span>¥ {{ items.goodPrice }}</span>
            </div>
            <div class="group_detail_buynum">
              x{{ items.goodNum }}
            </div>
          </div>
        </div>
      </div>
      <div class="group_total">
        共{{ item.allCount }}件,小计
        <span style="color: red; font-size: 18px; font-weight: 600">¥{{ item.allMoney }}</span>元
      </div>
    </div>
    <!-- 菜单选项 -->
    <van-cell-group style="text-align: left; margin: 10px auto">
      <van-cell title="商品金额" :value="'¥' + blanceInfolist.totelPrice" />
      <van-cell title="优惠" :value="'¥' + blanceInfolist.yhmoney" />
      <van-cell title="运费" :value="'¥' + blanceInfolist.totalFreight" />
      <!-- <van-cell title="悦积分" is-link value="无可用" /> -->

      <van-cell v-if="integral && integral.isHavePoint === 0" is-link>
        <template #title> 悦积分</template>
        <template>
          <span class="custom-no-value"> {{ integral.pointNum }} </span>
        </template>
      </van-cell>

      <van-cell
        v-if="integral && integral.isHavePoint === 1"
        is-link
        @click="showPointChange"
      >
        <template #title>
          悦积分
          <span class="custom-title">{{ integral.pointNum }}</span>
        </template>

        <template>
          <span
            :class="selectPoint.isSelect ? 'custom-value' : 'custom-no-value'"
          >
            {{ selectPoint.isSelect ? selectPoint.selectInfo.text : '去选择' }}
          </span>
        </template>
      </van-cell>

      <!-- 优惠券单元格 -->
      <van-coupon-cell
        :coupons="couponList"
        :chosen-coupon="chosenCoupon"
        @click="showList = true"
      />
    </van-cell-group>

    <!-- 优惠券列表 -->
    <van-popup
      v-model="showList"
      round
      position="bottom"
      style="height: 90%; padding-top: 4px"
    >
      <van-coupon-list
        :coupons="couponList"
        :chosen-coupon="chosenCoupon"
        :show-exchange-bar="false"
        @change="onChange"
      />
    </van-popup>
    <!-- 支付方式 -->
    <div class="pay_boxtype">
      <p class="pay_title">支付方式</p>
      <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell title="微信支付">
            <template #right-icon>
              <van-radio checked-color="#07c160" name="1" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <!-- :tip="blanceInfolist.isFilterGoodsText" -->
    <van-submit-bar
      :disabled="blanceInfolist.isFilterGoods === 1"
      :price="banCprice"
      :loading="isDisabledSubmitBtn"
      button-text="提交订单"
      @submit="onSubmit"
    />

    <!-- 积分规则 -->
    <van-popup
      v-model="showPoint"
      class="PointPop"
      round
      position="bottom"
      :style="{ height: '40%' }"
    >
      <div class="PointBox">
        <div class="PointTop">
          <div class="PointTopTitle">悦积分</div>
          <div class="closePop" @click="showPoint = false">
            <img src="../../assets/images/closesku.png">
          </div>
        </div>

        <div v-if="pointDetail" class="PointContentBox">
          <div
            v-if="pointDetail && pointDetail.deductionPoint === 0"
            class="PointRadio"
          >
            {{ pointDetail.text }}
          </div>

          <div
            v-if="pointDetail && pointDetail.deductionPoint != 0"
            class="PointRadio"
            @click="showRadioChange"
          >
            <div>
              {{ pointDetail.text }}
            </div>
            <div class="deductionBtn">
              <span v-if="showRadio">
                <img src="../../assets/images/deductionBtn.png">
              </span>
              <span v-else>
                <img src="../../assets/images/deductionBtnNo.png">
              </span>
            </div>
          </div>

          <div class="PointContentInfo">
            悦积分账户数：{{ pointDetail.availableBean }} 账户悦积分：{{ pointDetail.availableScore }}
            <span
              @click="showPop = true"
            >
              使用规则 <img src="../../assets/images/PointContentInfo.png">
            </span>
          </div>
        </div>

        <div
          v-if="pointDetail && pointDetail.deductionPoint == 0"
          class="noPointFooter"
        >
          使用
        </div>
        <div v-else class="PointFooter" @click="setPointChange">使用</div>
      </div>
    </van-popup>

    <van-popup v-model="showPop" class="showPop">
      <div class="showPopBox">
        <div class="showPopTitle">使用规则</div>
        <div
          v-if="pointDetail"
          class="showPopCentont"
          v-html="pointDetail.content"
        />

        <div class="showPopFooter" @click="showPop = false">我知道了</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  Cell,
  CellGroup,
  CouponCell,
  CouponList,
  Icon,
  Popup,
  Radio,
  RadioGroup,
  Stepper,
  SubmitBar,
  Tag,
  Toast
} from 'vant'

Vue.use(SubmitBar).use(Toast).use(CellGroup).use(Cell).use(Tag)
  .use(Icon).use(Stepper).use(Radio).use(RadioGroup).use(CouponCell)
  .use(CouponList).use(Popup)

import {
  getCartBlanceInfoV4,
  createOrderByCartV4,
  // newMallPayV4,
  // getPayVersion,
  myPoint,
  getProuctByPoint
} from '@/services/userApi'
import { weChatPay } from '@/utils/payment'

export default {
  data() {
    return {
      selectPoint: {
        isSelect: false,
        selectInfo: null
      },
      showPop: false,
      showRadio: false,
      pointDetail: null,
      showPoint: false,
      integral: null,
      yhmomney: 0,
      radio: '1',
      chosenContactId: 0,
      value: 1,

      chosenCoupon: -1,
      showList: false,
      couponList: [], // 优惠券列表
      disabledCoupons: [], // 不可用优惠卷
      Cid: '', // 优惠券id
      couponKeyId: 0, // 优惠券id
      // 订单数据
      cartId: '',
      Uid: '',
      openId: '',
      freightType: '',
      // 下单数据
      blanceInfolist: {
        yhmoney: 0,
        totelPrice: 0,
        totalFreight: 0
      }, // 下单集合
      addresslist: '', // 收货地址
      goodsList: '', // 商品列表
      isDisabledSubmitBtn: false,
      ordersn: '',
      banCprice: 0,
      initPrice: 0, // 初始价格
      wwwwshow: false
    }
  },
  computed: {
    cardType() {
      return this.chosenContactId !== null ? 'edit' : 'add'
    },
    currentContact() {
      const id = this.chosenContactId
      return id !== null ? this.list.filter((item) => item.id === id)[0] : {}
    }
  },
  created() {
    const _self = this
    _self.cartId = this.$route.query.cartId
    _self.Uid = localStorage.getItem('uid')
    _self.openId = localStorage.getItem('openid')
  },
  mounted() {
    this.getCartBlanceInfo()
  },
  methods: {
    // 点击使用积分
    setPointChange() {
      const couponInfo = this.couponList[this.chosenCoupon]
      const Price = couponInfo
        ? this.initPrice - couponInfo.value
        : this.initPrice
      if (this.showRadio) {
        this.selectPoint.selectInfo = this.pointDetail
        this.selectPoint.isSelect = this.showRadio
        this.selectPoint.selectInfo.text = this.pointDetail.text
        this.banCprice = Price - this.pointDetail.totalDeduction * 100
      } else {
        this.banCprice = Price
        this.selectPoint.isSelect = this.showRadio
      }
      this.showPoint = false

      // this.showRadio
    },
    // 是否选择积分规则
    showRadioChange() {
      this.showRadio = !this.showRadio
    },
    // 获取积分规则详情
    showPointChange() {
      const couponInfo = this.couponList[this.chosenCoupon]
      const Price = couponInfo
        ? this.initPrice - couponInfo.value
        : this.initPrice

      const arr = []
      this.goodsList.forEach((e) => {
        if (e.data) {
          e.data.forEach((el) => {
            const obj = {
              goodId: el.goodId,
              goodSkuId: el.goodSkuId,
              activityId: el.activityId
            }
            arr.push(obj)
          })
        }
      })

      const data = {
        mid: this.Uid,
        totalPrice: Price / 100,
        goodsList: arr
      }

      getProuctByPoint(data).then((res) => {
        if (Number(res.code) === 200) {
          // let content = res.data.content.replace(/\n/g, '<br>')
          res.data.content = res.data.content.replace(/\n/g, '<br>')
          this.pointDetail = res.data
          this.showRadio = this.selectPoint.isSelect
          this.showPoint = true
        }
      })
    },
    // 这里是悦积分的新规则接口
    myPoint() {
      const data = {
        mid: this.Uid,
        totalPrice: this.banCprice / 100
      }
      myPoint(data).then((resd) => {
        if (resd.code === 200) {
          this.integral = resd.data
        }
      })
    },
    // 普通商品下单
    getCartBlanceInfo() {
      const _self = this
      const params = {
        cartId: _self.cartId,
        uid: _self.Uid,
        addressIds: '',
        addressType: '',
        areaId: '',
        freightType: 1
      }
      getCartBlanceInfoV4(params).then((res) => {
        if (Number(res.code) === 200) {
          if (window.localStorage.getItem('addressItem')) {
            const ads_data = JSON.parse(
              window.localStorage.getItem('addressItem')
            )
            _self.addresslist = ads_data
          } else {
            _self.addresslist = res.data.address
          }
          if (res.data.isFilterGoods === 1) {
            Toast(res.data.isFilterGoodsText)
          }
          _self.couponlist = res.data.coupon
          _self.goodsList = res.data.newGoodsList
          _self.blanceInfolist = res.data
          _self.blanceInfolist.yhmoney = _self.$route.query.yhmoney
          _self.banCprice = (res.data.totelPrice + res.data.totalFreight) * 100
          _self.initPrice = (res.data.totelPrice + res.data.totalFreight) * 100
          _self.couponList = []
          res.data.coupon &&
          res.data.coupon.forEach((ele, index) => {
            _self.couponList.push({
              available: index,
              condition: '最多优惠' + ele.amount + '元',
              reason: '',
              value: ele.amount * 100,
              name: ele.couponName,
              startAt: new Date() / 1000,
              endAt:
                new Date(ele.endtime.replace(/-/g, '/')).getTime() / 1000,
              valueDesc: ele.amount,
              unitDesc: '元',
              Cid: ele.couponId,
              couponKeyId: ele.couponKeyId
            })
          })
          this.myPoint()
        } else {
          Toast(res.msg)
          _self.$router.push({ path: '/shopCar' })
        }
      })
    },
    // 普通商品下单
    createOrderByCart() {
      // _self.addresslist.address_id ||
      if (!(this.addresslist && (this.addresslist.address_id || this.addresslist.addressId))) {
        Toast('请选择地址')
        return
      }
      const _self = this
      const params = {
        position_from: 1,
        cartId: _self.cartId,
        uid: _self.Uid,
        addressIds: '',
        addressType: '',
        areaId: this.addresslist.address_id || this.addresslist.addressId,
        cid: _self.Cid,
        couponKeyId: _self.couponKeyId,
        type: 1,
        isHadBalance: 1,
        freightType: 1,
        deductionScore: 0,
        deductionBean: 0,
        totalDeduction: 0
      }

      if (this.selectPoint.isSelect) {
        params.deductionScore = this.selectPoint.selectInfo.deductionScore
        params.deductionBean = this.selectPoint.selectInfo.deductionBean
        params.totalDeduction = this.selectPoint.selectInfo.totalDeduction
      }

      createOrderByCartV4(params).then((res) => {
        if (Number(res.code) === 200) {
          this.$router.push({
            path: '/pay',
            query: {
              orderNo: res.ordersn,
              goodsId: this.goodsList[0].data[0].goodId
            }
          })
          // return

          // const _data = {
          //   ordersn: res.ordersn,
          //   type: 147,
          //   openId: _self.openId,
          //   returnUrl: 'https://web.yuetao.vip/odersky?odersn=' + res.ordersn
          // }
          // newMallPayV4(_data).then((res) => {
          //   if (Number(res.code) === 200) {
          //     const params = res.pay.getwayUrl
          //     window.location.replace(params)
          //   } else {
          //     Toast(res.msg)
          //   }
          // })

          // const ordersn = res.ordersn
          // getPayVersion({ type: 1 }).then((res) => {
          //   if (Number(res.code) === 200) {
          //     const version = res.data && res.data.status
          //     const _data = {
          //       ordersn: ordersn,
          //       type: 147,
          //       openId: _self.openId,
          //       payVersion: version,
          //       returnUrl: 'https://web.yuetao.vip/odersky?odersn=' + ordersn
          //     }

          //     newMallPayV4(_data).then((res) => {
          //       if (Number(res.code) === 200) {
          //         _self.ordersn = res.ordersn
          //         // let params = res.pay.getwayUrl;
          //         // window.location.replace(params);

          //         const params = res.pay.getwayBody
          //         _self.wxPay(params)
          //       } else {
          //         Toast(res.msg)
          //       }
          //     })
          //   }
          // })
        } else {
          Toast(res.msg)
          _self.$router.push({ path: '/shopCar' })
        }
      })
    },
    /**
     * 调用微信支付
     * 2021-06-04 10:22:15
     * @author SnowRock
     * @param params
     */
    wxPay(params) {
      var that = this
      weChatPay(params).then(res => {
        // 支付成功后的操作
        Toast('支付成功')
        that.isDisabledSubmitBtn = true
        that.$router.push({
          path: '/oderSky',
          query: { ordersn: that.ordersn }
        })
      }).catch(e => {
        if (e) {
          const { msg = null } = e
          if (msg === 'isNotWeChat') {
            Toast('请在微信浏览器下进行支付')
          }
          if (msg === 'cancel') {
            // 取消支付的操作
            Toast('取消支付')
            that.$router.push({
              path: '/oderSky',
              query: { ordersn: that.ordersn }
            })
          }
          if (msg === 'fail') {
            // 支付失败的操作
            Toast('支付失败')
            that.$router.push({
              path: '/oderSky',
              query: { ordersn: that.ordersn }
            })
          }
        }
        that.isDisabledSubmitBtn = false
      })
    },
    // 提交订单
    onSubmit() {
      const _self = this
      _self.isDisabledSubmitBtn = true

      _self.createOrderByCart()
    },

    onChange(index) {
      this.showList = false
      this.chosenCoupon = index
      const couponInfo = this.couponList[index]
      this.banCprice = couponInfo
        ? this.initPrice - couponInfo.value
        : this.initPrice
      this.Cid = couponInfo ? couponInfo.Cid : ''
      this.couponKeyId = couponInfo ? couponInfo.couponKeyId : ''
    },
    // 选地址
    goAddress() {
      this.$router.push({
        path:
          '/addressList?id=' + this.addresslist.addressId ||
          this.addresslist.address_id
      })
    }
  }
}
</script>

<style lang="less" scoped>
.address-empty {
  margin-bottom: 30px;
  text-align: center;
  font-size: 14px;
}

.placeOder_content {
  width: 100%;
  height: auto;
  overflow: auto;
  background: #f6f6f6;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .vancard-header {
    width: 100%;
    overflow: auto;
    position: relative;

    background: #fffef3;
    padding-top: 5px;

    .vancard-first {
      color: #000;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      margin-left: 15px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .vancard-second {
      text-align: left;
      margin-left: 15px;
      margin-bottom: 15px;
      width: 75%;
      line-height: 20px;
      overflow: hidden;

      .vancard-is_default {
        float: left;
        font-size: 12px;
        border: 1px solid red;
        color: red;
        padding: 1px 3px;
        vertical-align: middle;
        margin-right: 20px;
      }

      .vancard-address {
        float: left;
        width: 80%;
      }
    }

    .vancard-icon {
      position: absolute;
      right: 20px;
      top: 30px;
      font-size: 18px;
    }
  }

  .vancard-header::before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 0.05333rem;
    background: -webkit-repeating-linear-gradient(135deg,
    #ff6c6c 0,
    #ff6c6c 20%,
    transparent 0,
    transparent 25%,
    #1989fa 0,
    #1989fa 45%,
    transparent 0,
    transparent 50%);
    background: repeating-linear-gradient(-45deg,
    #ff6c6c 0,
    #ff6c6c 20%,
    transparent 0,
    transparent 25%,
    #1989fa 0,
    #1989fa 45%,
    transparent 0,
    transparent 50%);
    background-size: 2.13333rem;
    content: "";
  }

  .group_xxon {
    width: 100%;
    height: auto;
    background: #fff;
    padding: 10px 0;

    .group_xxon_title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 15px;
      padding-top: 10px;

      .img {
        width: 20px;
        height: 20px;
      }

      .name {
        font-size: 18px;
        font-weight: 600;
        color: #000;
        margin-left: 10px;
      }
    }

    .group_box {
      width: 100%;
      height: auto;
      overflow: auto;
      background: #fff;

      .group_top {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 135px;
        overflow: auto;

        .group_image {
          width: 100px;
          height: 100px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .group_detail {
          width: 225px;
          height: 100px;
          text-align: left;
          font-size: 12px;
          padding-left: 15px;
          position: relative;
          line-height: 20px;

          .group_detail_title {
            color: #333333;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 13px;
            padding: 0 5px;
          }

          .group_detail_txt {
            color: #999999;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .group_detail_lay {
            color: #ff001b;
            font-size: 13px;
            font-weight: 600;

            span {
              color: #666;
              text-decoration: line-through;
            }

            // margin-top: 30px;
          }

          .group_detail_buynum {
            position: absolute;
            bottom: 5px;
            right: 0;
          }
        }
      }

      .group_bottom {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 90%;
        margin: 10px auto;
        background: #e6e6e6;
        font-size: 13px;
        color: #999999;
        padding: 5px 10px;
        border-radius: 5px;
      }
    }

    .group_total {
      text-align: right;
      padding-right: 25px;
      font-size: 15px;
      color: #000;
      line-height: 30px;
    }
  }

  .pay_boxtype {
    text-align: left;
    background: #fff;
    border-bottom: 100px solid #f6f6f6;
    margin-top: 20px;

    .pay_title {
      line-height: 40px;
      padding-left: 15px;
      font-size: 15px;
      font-weight: 600;
    }
  }
}

//强改
/deep/ .van-nav-bar {
  .van-icon {
    color: #000;
  }
}

/deep/ .van-cell__value {
  color: red;
}

.closePop {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 15px;

  img {
    width: 15px;
    height: 15px;
  }
}

.custom-title {
  color: #969799;
}

.custom-no-value {
  color: #969799;
}

.custom-value {
  color: red;
}

/deep/ .PointPop {
  /deep/ .van-popup {
    background: #fff !important;
  }
}

.PointBox {
  background: #fff;
  position: relative;
  height: 100%;

  .PointTop {
    padding: 10px 0;
    height: 50px;
    position: relative;

    .PointTopTitle {
      line-height: 30px;
      font-weight: bold;
    }
  }

  .PointContentBox {
    padding: 0 20px;
    text-align: left;
  }

  .PointFooter {
    position: absolute;
    width: 90%;
    margin: 0 auto;
    height: 40px;
    background: #f7263c;
    color: #fff;
    line-height: 40px;
    border-radius: 10px;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 20px;
  }

  .noPointFooter {
    position: absolute;
    width: 90%;
    margin: 0 auto;
    height: 40px;
    background: #ccc;
    color: #fff;
    line-height: 40px;
    border-radius: 10px;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 20px;
  }
}

.PointRadio {
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
  font-weight: bold;
  position: relative;

  .deductionBtn {
    position: absolute;
    right: 0;
    top: 10px;

    img {
      width: 15px;
      height: 15px;
    }
  }
}

.PointContentInfo {
  padding: 10px 0;
  font-weight: 400;
  color: #666666;

  span {
    color: #f7263c;
    margin-left: 15px;

    img {
      width: 15px;
      height: 15px;
      vertical-align: -2px;
    }
  }
}

.showPop {
  width: 90%;
  height: 240px;
  border-radius: 10px;
  padding: 10px;

  .showPopBox {
    width: 100%;
    height: 100%;
    position: relative;

    .showPopTitle {
      font-weight: bold;
    }

    .showPopCentont {
      padding: 10px 10px;
      text-align: left;
      line-height: 24px;
      font-size: 13px;
      font-weight: 400;
    }

    .showPopFooter {
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 90%;
      height: 40px;
      background: #f7263c;
      color: #fff;
      border-radius: 10px;
      line-height: 40px;
    }
  }
}
</style>
